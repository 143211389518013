<template>
<div class="customjs">

  <div class="field ">
        <label class="label">Add your custom js to landing</label>
        <p style="margin-bottom: 15px;">Add all your functions to this textarea</p>
        <b>&lt;script&gt;</b>

        <textarea
            style="display:block;height:200px;width: 100%;border:1px solid #eee;border-radius: 15px;padding:5px;"
            v-model="landing.advanced.global.custom_scripts"
            rows="4"
            placeholder="Write your custom JS"
        ></textarea>
        <b>&lt;script/&gt;</b>
        </div>
</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: "CustomJsPage",
  computed:{
    ...mapState(['landing']),
  },
  methods:{
    setChanges(){
      this.$store.commit('setLanding',this.landing);
    }
  }
}
</script>

<style scoped lang="scss">
.v-textarea textarea {
  line-height: 40px;
}
</style>